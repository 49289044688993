<template>
  <div v-if="dialog" class="relative z-50">
    <div class="fixed inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-auto">
      <div class="flex items-center justify-center h-full">
        <div class="bg-white rounded-lg p-4 w-full max-w-xs">
          <div>
            <div class="text-lg font-bold">Create PIN TripwePay</div>
            <div class="flex flex-col gap-3 mt-4">
              <div>
                <label class="font-bold" for="">New PIN</label>
                <v-otp-input
                  v-show="pin.visible"
                  ref="pinInput"
                  class="flex justify-between"
                  :num-inputs="6"
                  separator=""
                  :input-type="pin.type"
                  autocomplete="off"
                  input-classes="w-10 h-10 border border-gray-400 rounded-lg text-center"
                  @on-change="setPin"
                />
              </div>
              <div>
                <label class="font-bold" for="">Confirm New PIN</label>
                <v-otp-input
                  v-show="confirm_pin.visible"
                  ref="confirmPinInput"
                  class="flex justify-between"
                  :num-inputs="6"
                  separator=""
                  :input-type="confirm_pin.type"
                  autocomplete="off"
                  input-classes="w-10 h-10 border border-gray-400 rounded-lg text-center"
                  @on-change="setConfirmPin"
                />
                <small
                  v-if="errorNotSame"
                  class="text-red-500 text-sm font-semibold"
                  >Confirmation PIN not same with PIN</small
                >
              </div>
            </div>
          </div>
          <div class="flex mt-8 justify-end">
            <button
              @click="cancel"
              class="bg-gray-200 hover:bg-gray-300 disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg"
            >
              Cancel
            </button>
            <button
              @click="confirm"
              :disabled="isInvalidPin"
              class="bg-primary text-white hover:bg-primary-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg ml-4"
            >
              {{ button }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal-otp ref="modalOtp"></modal-otp>
  </div>
</template>

<script>
export default {
  props: {
    newPin: Boolean,
  },
  components: {
    ModalOtp: () => import("./ModalOtp.vue"),
  },
  data() {
    return {
      dialog: false,
      pin: { value: "", visible: true, type: "number" },
      confirm_pin: { value: "", visible: true, type: "number" },
      button: "",
    };
  },

  computed: {
    errorNotSame() {
      return this.pin.value.toString() !== this.confirm_pin.value.toString();
    },
    isInvalidPin() {
      return (
        this.pin.value.toString().length != 6 ||
        this.confirm_pin.value.toString().length != 6 ||
        this.errorNotSame
      );
    },
  },
  methods: {
    open({ button }) {
      this.button = button ?? "OK";
      this.pin = { value: "", visible: true, type: "number" };
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async confirm() {
      // this.pin.visible = false;
      this.pin.type = "number";
      try {
        const validate = await this.$http.post(
          `${this.$apiTripweWebV2}/users/create-pin/validate`,
          {
            new_pin: this.pin.value,
            confirm_new_pin: this.confirm_pin.value,
          }
        );
        if (validate.data.status == 200) {
          const confirmed = await this.$refs.modalOtp.open({
            button: "OK",
          });
          if (confirmed != null) {
            const res = await this.$http.post(
              `${this.$apiTripweWebV2}/users/create-pin`,
              {
                new_pin: this.pin.value,
                confirm_new_pin: this.confirm_pin.value,
                otp: confirmed,
              }
            );

            if(res.data.status == 200){
              let user = this.$store.state.user;
              user.status_pin = 1;
              console.log(user)
              this.$store.commit("setUser", user)
              this.$toasted.global.success(res.data.message);
              this.dialog = false;
              this.resolvePromise(this.pin.value);
            }
          }
        }
      } catch (error) {

      }
    },
    async cancel() {
      this.resolvePromise(null);
      this.dialog = false;
    },
    setPin(val) {
      this.pin.type = "password";
      this.pin.value = val;
    },
    setConfirmPin(val) {
      this.confirm_pin.type = "password";
      this.confirm_pin.value = val;
    },
  },
};
</script>
